
import './App.css';
import Navbar  from './component/Navbar';
import Contact from './component/contact/Contact';
import { Route ,Routes} from 'react-router-dom';
import Home from './component/home/Home'

function App() {

  return (
    <div className="App">
     <Navbar/>

     <Routes>
        <Route path="/" element={<Home />} />           {/* Home page route */}
        <Route path="/contact" element={<Contact />} /> {/* Contact page route */}
        {/* Add more routes as needed */}
      </Routes>
  </div>
  );
}

export default App;
