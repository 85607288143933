import React from 'react'
import './home.css'

export default function Home() {
  return (
    <div className='home'>
      <h1>Welcome to FICER USA!</h1>
    </div>
  )
}
