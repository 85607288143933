import React, { useState } from 'react';
import './contact.css';

export default function Contact() {
  const [formData, setFormData] = useState({
    email: '',
    subject: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert('Message sent!');
  };

  return (
    <div className="contact">
      <h1>Contact Us</h1>
      <div className="office_address">
        <p> Address: 100 FRANKLIN ST APT 103 default </p>
        <p> MORRISTONWN, NJ, 07960-549 </p>
        <p>Tel: +1 (929) 280 8866</p>
        <p>Tel: +1 (973) 955 3628</p>
        <p>Tel: +1 (973) 722 2298</p>
      </div>

      <form onSubmit={handleSubmit} className="contact-form">
        <input
          placeholder="Email"
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <input
          placeholder="Subject"
          type="text"
          name="subject"
          value={formData.subject}
          onChange={handleChange}
          required
        />
        <textarea
          placeholder="Message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          required
        />
        <button type="submit" className="send-button">Send</button>
      </form>
    </div>
  );
}
